<template>
  <v-menu
      offset-y
      :close-on-click="true"
      :close-on-content-click="false"
      v-model="dropdown_opened"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="task_selection"
          v-bind="attrs"
          v-on="on"
          :disabled="loading"
          @click="loadCustomers()"
      >
        <span
            v-if="value && value.customer"
            :style="{ color: value.customer.color }"
        >
          {{ value.customer.name }}:
        </span>
        <span :style="{ fontStyle: value && value.name ? null : 'italic' }">
          {{ value && value.name ? value.name : defaultLabel }}
        </span>
      </v-btn>
    </template>
    <v-list dense>
      <v-progress-linear
          indeterminate
          v-if="loadingCustomers"
      ></v-progress-linear>
      <v-list-item>
        <v-list-item-title>
          <v-text-field
              prepend-inner-icon="search"
              label="Kunde suchen"
              v-model="searchCustomer"
              clearable
          ></v-text-field>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="!customers.length && !loading">
        <v-list-item-title>
          Keine Kunden gefunden
          <router-link to="/kunden" @click="dropdown_opened = false"
          >Kunden anlegen
          </router-link
          >
        </v-list-item-title>
      </v-list-item>
      <v-list-group
          @click="customerId = customer.id"
          no-action
          v-for="customer in customers"
          :key="customer.id"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title :style="{ color: customer.color }">{{
                customer.name
              }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item>
          <v-list-item-title>
            <v-row>
              <v-col>
                <v-text-field
                    autofocus
                    autocomplete="off"
                    data-lpignore="true"
                    prepend-inner-icon="search"
                    @input="searchTaskCustomerId = customer.id"
                    :loading="loadingTasks"
                    dense
                    placeholder="Tätigkeit suchen"
                    v-model="searchTask"
                ></v-text-field>
              </v-col>
              <v-col>
                <form
                    v-on:submit.prevent="
                addTask(customer.new_task_name);
                customer.new_task_name = '';
              "
                >
                  <v-text-field
                      prepend-inner-icon="add"
                      :loading="loadingTasks"
                      dense
                      placeholder="Neue Tätigkeit"
                      v-model="customer.new_task_name"
                  ></v-text-field>
                </form>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            @click="
            $emit('input', { ...task, customer });
            $emit('change', { ...task, customer });
            dropdown_opened = false;
          "
            link
            v-for="task in tasks.filter(t => t.customer_id == customer.id)"
            :key="task.id"
        >
          <v-list-item-title>
            <div class="editable" @click.stop.prevent="">
              <input
                  style="width: 100%"
                  type="text"
                  v-model="task.name"
                  @keyup.enter.stop.prevent="updateTask(task)"
                  @blur="updateTask(task)"
              />
            </div>
          </v-list-item-title>
          <v-list-item-title class="task-description">
            <div class="editable" @click.stop.prevent="">
              <input
                  style="width: 100%"
                  type="text"
                  v-model="task.description"
                  placeholder="Beschreibung"
                  @keyup.enter.stop.prevent="updateTask(task)"
                  @blur="updateTask(task)"
              />
            </div>
          </v-list-item-title>
          <v-list-item-action-text
              v-if="task.hourly_rate"
              style="white-space: nowrap;"
          >{{ task.earnings }} € | {{
              formatSeconds(task.duration)
            }}
            <v-btn icon class="ml-2"
            >
              <v-icon size="18">play_arrow</v-icon>
            </v-btn
            >
          </v-list-item-action-text
          >
        </v-list-item>
        <v-list-item v-if="tasksTotal > tasksPerPage">
          <v-list-item-title>
            <button
                class="load-more px-0"
                @click="tasksPerPage += 20"
            >
              Mehr laden ...
            </button>
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
      <button
          class="load-more"
          @click="customersPerPage += 10"
          v-if="customersTotal > customersPerPage"
      >
        Mehr laden ...
      </button>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
.load-more {
  font-size: 0.7em;
  padding: 0 17px;
  text-decoration: underline;

  &:focus,
  &:active {
    outline: none;
    color: grey;
  }
}

.task_selection.v-btn:not(.v-btn--round):not(.success).v-btn--has-bg {
  flex-grow: 2;
  color: inherit;
  justify-content: inherit;
  border: none !important;
  text-transform: none;
  font-weight: 400;
  padding: 0;
  height: auto;
  background-color: transparent;
  letter-spacing: 0em;

  &:hover {
    background-color: transparent;

    &:before {
      opacity: 0;
    }
  }
}

.task-description {
  font-weight: normal !important;
}
</style>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultLabel: {
      type: String,
      default: "Gelöschte Tätigkeit"
    }
  },
  data() {
    return {
      customerId: null,
      searchCustomer: "",
      searchTask: "",
      searchTaskCustomerId: null,
      customersPerPage: 10,
      tasksPerPage: 20,
      dropdown_opened: false,
      loadingTasks: false,
      loadingCustomers: false
    };
  },
  computed: {
    customers() {
      return this.$store.getters["customers/list"].slice(
          0,
          this.customersPerPage
      );
    },
    customersTotal() {
      return this.$store.getters["customers/listTotal"];
    },
    tasksTotal() {
      return this.$store.getters["tasks/listTotal"];
    },
    tasks() {
      return this.$store.getters["tasks/list"];
    },
    current_track_db() {
      return this.$store.getters["tracks/current"];
    }
  },
  watch: {
    current_track_db: {
      deep: true,
      immediate: true,
      handler(track) {
        if (track && track.start) {
          this.current_track = track;
        }
      }
    },
    customerId: {
      handler() {
        this.searchTaskCustomerId = null;
        this.searchTask = '';
        this.tasksPerPage = 20;
        this.loadTasks();
      }
    },
    searchCustomer: {
      handler() {
        this.debounce(() => {
          this.loadCustomers();
        }, 500);
      }
    },
    searchTask: {
      handler() {
        this.debounce(() => {
          if (this.searchTaskCustomerId) {
            this.loadTasks();
          }
        }, 500);
      }
    },
    customersPerPage: {
      handler() {
        this.loadCustomers();
      }
    },
    tasksPerPage: {
      handler() {
        this.loadTasks();
      }
    }
  },
  methods: {
    updateTask(task) {
      this.$store
          .dispatch("tasks/update", task)
          .then(() => {
            this.$store.dispatch("alerts/success", "Tätigkeit gespeichert");
          })
          .catch(e => {
            if (e.response) {
              for (const error of e.response.data.errors) {
                this.$store.dispatch("alerts/error", error.detail);
              }
            }
          });
    },
    loadCustomers() {
      this.loadingCustomers = true;
      this.$store
          .dispatch("customers/list", {
            filter: {name: this.searchCustomer},
            page: {number: 0, size: this.customersPerPage}
          })
          .then(() => {
            this.loadingCustomers = false;
          });
    },
    loadTasks() {
      this.loadingTasks = true;
      return this.$store
          .dispatch("tasks/list", {
            page: {size: this.tasksPerPage},
            filter: {name: this.searchTask, customer_id: this.customerId, with_hours: true}
          })
          .then(() => {
            this.loadingTasks = false;
          });
    },
    addTask(name) {
      this.$store
          .dispatch("tasks/add", {
            type: "tasks",
            name: name,
            customer_id: this.customerId
          })
          .then(() => {
            this.loadTasks();
            this.dropdown_opened = false;
            this.$emit("input", this.$store.getters["tasks/task"]);
            this.$emit("change", this.$store.getters["tasks/task"]);
          });
    }
  }
};
</script>
